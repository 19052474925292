import qs from 'querystring';
import { V1OrdersOrder } from '@wix/ambassador-membership-api/types';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import { Logger } from '@wix/bi-logger-membership';
import { PaymentCompleteResultPublic } from '@wix/cashier-payments-widget/dist/src/sdk/types/PaymentCompleteResult';

import { CheckoutStage, IntegrationData } from '../types/common';
import { BIRole, toBIPaymentType } from '../utils/bi';
import { PageType } from '../utils/pageType';

type WidgetType = 'page' | 'list';
type Referral = IntegrationData['biOptions'];

export interface CommonBIData {
  appInstanceId: string;
  metaSiteId?: string;
  biToken?: string;
  visitorId?: string;
  siteMemberId?: string;
  role?: BIRole;
}

export class PricingPlansBi {
  util: Logger['util'];

  constructor(private logger: Logger, private data: CommonBIData) {
    this.util = logger.util;
  }

  plansPageView(referral: Referral, widgetType: WidgetType = 'list') {
    return this.logger.plansPageView({
      widgetType,
      referral_info: referral?.referralInfo,
      referralId: referral?.referralId,
      appInstanceId: this.data.appInstanceId,
      biToken: this.data.biToken,
      role: this.data.role,
      site_member_id: this.data.siteMemberId,
      visitor_id: this.data.visitorId,
    });
  }

  planPurchaseClick(planGuid: string, widgetType: WidgetType = 'list') {
    return this.logger.planPurchaseClick({
      planGuid,
      widgetType, // list, featured, button -- only list widget exists at the moment.
      appInstanceId: this.data.appInstanceId,
      biToken: this.data.biToken,
      role: this.data.role,
      site_member_id: this.data.siteMemberId,
      visitor_id: this.data.visitorId,
    });
  }

  thankYouPageCtaButtonClick() {
    return this.logger.thankYouPageCtaButtonClick({
      appInstanceId: this.data.appInstanceId,
      biToken: this.data.biToken,
      buttonName: 'null',
    });
  }

  upgradeReferralClick(referralInfo: string) {
    return this.logger.upgradeReferralClick({
      appInstanceId: this.data.appInstanceId,
      biToken: this.data.biToken,
      isPreview: false,
      referralInfo,
    });
  }

  checkoutStage(stage: CheckoutStage, planGuid?: string) {
    return this.logger.checkoutStage({
      planGuid,
      stage,
      appInstanceId: this.data.appInstanceId,
      biToken: this.data.biToken,
    });
  }

  planPurchased(result: PaymentCompleteResultPublic, plan: PublicPlan, order: V1OrdersOrder) {
    return this.logger.planPurchased({
      appInstanceId: this.data.appInstanceId,
      biToken: this.data.biToken,
      role: this.data.role,
      site_member_id: this.data.siteMemberId,
      duration: plan.paymentOptions?.validFor?.period?.amount,
      paymentType: toBIPaymentType(plan.paymentOptions?.recurring),
      planGuid: plan.id,
      currency: plan.paymentOptions?.price?.currency,
      price: parseFloat(plan.paymentOptions?.price?.amount ?? '0') * 100,
      paymentStatus: result.clientStatus,
      paymentMethodType: result.paymentMethod,
      membershipId: order.id,
    });
  }

  settingsOpened() {
    return this.logger.settingsOpened({
      appInstanceId: this.data.appInstanceId,
      biToken: this.data.biToken,
      referralInfo: String(qs.parse(window.location.search).referralInfo),
    });
  }

  widgetSettingsAddOnsModalActions(action: string, options?: { pageName?: string; widgetType?: string }) {
    return this.logger.widgetSettingsAddOnsModalActions({
      action,
      appInstanceId: this.data.appInstanceId,
      biToken: this.data.biToken,
      ...options,
    });
  }

  createPlanClick(formOrigin: string) {
    return this.logger.createPlanClick({
      formOrigin,
      appInstanceId: this.data.appInstanceId,
      biToken: this.data.biToken,
    });
  }

  widgetClickOnPlanHide(planGuid: string, hidden: boolean) {
    return this.logger.widgetClickOnPlanHide({
      planGuid,
      hidden,
      appInstanceId: this.data.appInstanceId,
      biToken: this.data.biToken,
    });
  }

  widgetPlanOrderChanged() {
    return this.logger.widgetPlanOrderChanged({
      appInstanceId: this.data.appInstanceId,
      biToken: this.data.biToken,
    });
  }

  actionButtonClicked(action: string, planGuid: string) {
    return this.logger.actionButtonClicked({
      action,
      planGuid,
      appInstanceId: this.data.appInstanceId,
      biToken: this.data.biToken,
    });
  }

  planSettingsPanelOpen() {
    return this.logger.planSettingsPanelOpen({
      appInstanceId: this.data.appInstanceId,
      biToken: this.data.biToken,
    });
  }

  membershipStatusLoaded(pageType: PageType) {
    return this.logger.membershipStatusLoaded({
      appInstanceId: this.data.appInstanceId,
      biToken: this.data.biToken,
      role: this.data.role,
      site_member_id: this.data.siteMemberId,
      visitor_id: this.data.visitorId,
      membershipStatus: toMembershipStatus(pageType),
    });
  }
}

function toMembershipStatus(pageType: PageType) {
  switch (pageType) {
    case PageType.DEFAULT_SUCCESS:
      return 'success';
    case PageType.VERTICAL_SUCCESS:
      return 'vertical_success';
    case PageType.ERROR:
      return 'error';
    default:
      return undefined;
  }
}
